// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var row = Cx.cx([
      "flex",
      "justify-between",
      "mx-0!"
    ]);

var grid = Cx.cx([
      "lg:px-0!",
      "max-lg:px-8!"
    ]);

var breadcrumbText = Cx.cx([
      "text-sm",
      "font-medium",
      "leading-none",
      "text-gray-800"
    ]);

var breadcrumbContainer = Cx.cx([
      "w-full",
      "no-scrollbar",
      "pt-7",
      "flex",
      "justify-start",
      "items-center",
      "overflow-x-auto",
      "flex-nowrap!",
      "whitespace-nowrap",
      "mx-auto",
      "max-lg:py-4",
      "max-lg:pl-7",
      "lg:max-w-4xl",
      "xl:max-w-6xl"
    ]);

var detailText = Cx.cx([
      "text-base",
      "leading-6",
      "mb-7",
      "text-gray-700",
      "mt-4",
      "max-md:text-sm",
      "max-md:leading-5"
    ]);

var summaryText = Cx.cx([
      "text-lg",
      "leading-6",
      "mt-7",
      "text-gray-700",
      "max-md:text-base",
      "max-md:mt-6"
    ]);

var contentContainer = Cx.cx([
      "lg:px-0",
      "lg:min-w-xl",
      "xl:px-0",
      "xl:min-w-4xl"
    ]);

var resourceTitle = Cx.cx([
      "mt-10",
      "text-3xl",
      "font-semibold",
      "leading-10",
      "text-black",
      "max-md:text-[22px]",
      "max-md:leading-7",
      "max-md:mb-4"
    ]);

var mobileImageContainer = Cx.cx([
      "bg-gray",
      "w-full",
      "flex",
      "justify-center",
      "items-center",
      "min-h-62",
      "rounded-sm",
      "lg:hidden"
    ]);

var mobileHiddenImageContainer = Cx.cx([
      "bg-gray",
      "w-full",
      "flex",
      "justify-center",
      "items-center",
      "mb-5",
      "rounded-sm",
      "[&_img]:h-45",
      "[&_img]:w-auto",
      "[&_img]:max-w-40",
      "max-lg:hidden",
      "lg:py-12",
      "lg:min-h-120",
      "xl:py-12",
      "xl:min-h-80"
    ]);

var sideColumn = Cx.cx([
      "ml-0",
      "max-md:mt-10",
      "lg:max-w-70!",
      "px-0"
    ]);

var downloadButton = Cx.cx([
      "xs:mb-7",
      "flex-1",
      "w-full",
      "!p-2",
      "!pt-[9px]",
      "!pb-[11px]",
      "!max-h-[40px]"
    ]);

var fileContainer = Cx.cx([
      "flex",
      "items-center"
    ]);

var lineSeparator = Cx.cx([
      "border-b",
      "border-gray-100",
      "mb-7",
      "small:mt-7",
      "large:mt-12"
    ]);

var badgeWrapper = Cx.cx([
      "flex",
      "relative",
      "flex-nowrap"
    ]);

var badgeInactive = Cx.cx([
      "inline-flex",
      "items-center",
      "justify-center",
      "transition-all",
      "border",
      "no-underline",
      "border-solid",
      "border-blue-700",
      "bg-blue-700",
      "text-center",
      "text-white",
      "rounded-sm",
      "whitespace-nowrap",
      "py-1",
      "px-2.5",
      "text-xl",
      "cursor-auto",
      "outline-none",
      "disabled:pointer-events-none"
    ]);

var container = "pb-20";

var breadcrumbWrapper = "w-full";

var mobileImage = "min-h-45";

export {
  row ,
  grid ,
  container ,
  breadcrumbText ,
  breadcrumbWrapper ,
  breadcrumbContainer ,
  detailText ,
  summaryText ,
  contentContainer ,
  resourceTitle ,
  mobileImage ,
  mobileImageContainer ,
  mobileHiddenImageContainer ,
  sideColumn ,
  downloadButton ,
  fileContainer ,
  lineSeparator ,
  badgeWrapper ,
  badgeInactive ,
}
/* row Not a pure module */
